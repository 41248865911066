import React, { useEffect, useState } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Col,
  Row,
  Table,
  Space,
  Modal,
  Form,
  message,
  Tag,
  Button,
  Popconfirm,
} from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getServiceRequests,
  editServiceRequests,
  deleteServiceRequests,
  API_BASE_PHOTO_URL,
} from "../AxiosApis/api";
import PhotoGallery from "../orders/photoGallery";

const ServiceRequests = () => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchServiceRequests();
  }, []);

  const fetchServiceRequests = async () => {
    try {
      const response = await getServiceRequests();
      const sortedRequests = response.data.data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setServiceRequests(sortedRequests);
    } catch (error) {
      message.error("Failed to fetch service requests.");
    }
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      status: record.status,
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteServiceRequests(id);
      setServiceRequests(
        serviceRequests.filter((request) => request._id !== id)
      );
      message.success("Service request deleted successfully.");
    } catch (error) {
      message.error("Failed to delete service request.");
    }
  };

  const handleContacted = async () => {
    try {
      await editServiceRequests(currentRecord._id, { status: "contacted" });
      setServiceRequests(
        serviceRequests.map((request) =>
          request._id === currentRecord._id
            ? { ...request, status: "contacted" }
            : request
        )
      );
      setIsModalVisible(false);
      message.success("Service request updated to contacted.");
    } catch (error) {
      message.error("Failed to update service request.");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const getStatusTag = (status) => {
    const statusConfig = {
      pending: { color: "orange", text: "Pending" },
      contacted: { color: "green", text: "Contacted" },
    };
    return statusConfig[status] || { color: "default", text: status };
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name", width: 60 },
    { title: "Phone Number", dataIndex: "number", key: "number", width: 60 },
    { title: "Brand Name", dataIndex: "service", key: "service", width: 60 },
    { title: "Brand Model", dataIndex: "model", key: "model", width: 60 },
    { title: "Issue", dataIndex: "desc", key: "desc", width: 150 },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 60,
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Contacted", value: "contacted" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        const { color, text } = getStatusTag(status);
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 40,
      render: (text, record) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Are you sure to delete this order?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={serviceRequests}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      <Modal
        title="Service Request"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <div>
          <p>
            <strong>Full Name:</strong> {currentRecord?.name}
          </p>
          <p>
            <strong>Phone Number:</strong> {currentRecord?.number}
          </p>
          <p>
            <strong>Address:</strong> {currentRecord?.address}
          </p>
          <p>
            <strong>Pincode:</strong> {currentRecord?.pincode}
          </p>
          <p>
            <strong>Location:</strong> {currentRecord?.location}
          </p>
          <p>
            <strong>Issue:</strong> {currentRecord?.desc}
          </p>
          <p>
            <strong>Issue Photo:</strong>{" "}
            <PhotoGallery
              photos={currentRecord?.photos}
              orderId={currentRecord?._id}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>
          <p>
            <strong>Brand Name:</strong> {currentRecord?.service}
          </p>
          <p>
            <strong>Brand Model:</strong> {currentRecord?.model}
          </p>
          <p>
            <strong>Date:</strong> {currentRecord?.date?.toLocaleString()}
          </p>
          <p>
            <strong>Time:</strong> {currentRecord?.time}
          </p>
          <p>
            <strong>Status:</strong> {currentRecord?.status}
          </p>
        </div>
        <Button type="primary" onClick={handleContacted}>
          Mark as Contacted
        </Button>
      </Modal>
    </AppLayout>
  );
};

export default ServiceRequests;
