import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Table,
  message,
  Tag,
  Space,
  Popconfirm,
  Input,
  AutoComplete,
} from "antd";
import AppLayout from "../../layout/Layout";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  getAllCategories,
  getAllSubCategories,
  getAllProducts,
  getAllOrders,
  requestProduct,
  editProductRequest,
  deleteRequestProduct,
  getProductRequests,
} from "../AxiosApis/api";
import { UserContext } from "../../UserContext";

const StoreRequest = () => {
  const [form] = Form.useForm();
  const [editingForm] = Form.useForm();
  const { userDetails, loading } = useContext(UserContext);
  const [requestModal, setRequestModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [productRequests, setProductRequests] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [filteredOrderId, setFilteredOrderId] = useState([]);

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();

    const fetchData = async () => {
      await fetchCategories();
      await fetchOrders(email);
      if (userDetails) {
        await fetchProductRequests();
      }
    };

    if (!loading && userDetails) {
      fetchData();
    }
    if (userDetails && userDetails.name) {
      form.setFieldsValue({
        engineerId: userDetails.name,
      });
    }
  }, [userDetails, loading, form]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const fetchCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response.data.allcategory || []);
    } catch (error) {
      message.error("Failed to load categories");
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await getAllSubCategories({ categoryId });
      setSubCategories(response.data.allSubCategory || []);
      form.resetFields(["subCategoryId", "productId"]);
    } catch (error) {
      message.error("Failed to load subcategories");
    }
  };

  const fetchProducts = async (subCategoryId) => {
    try {
      const response = await getAllProducts({ subCategoryId });
      setProducts(response.data.allProducts || []);
      form.resetFields(["productId"]);
    } catch (error) {
      message.error("Failed to load products");
    }
  };

  const fetchOrders = async () => {
    const email = getEmailForFetch();

    if (!email) {
      message.error("Email is required to fetch orders");
      return;
    }
    try {
      const response = await getAllOrders({ email });
      const orders = response.data.data.orders.map((order) => ({
        value: order.orderId,
      }));

      setOrders(orders);
    } catch (error) {
      message.error("Failed to load orders");
    }
  };

  const handleCategoryChange = (value) => {
    const email = getEmailForFetch();
    fetchSubCategories(email, value);
    form.resetFields(["subCategoryId", "productId"]);
  };

  const handleSubCategoryChange = (value) => {
    const email = getEmailForFetch();
    fetchProducts(email, value);
    form.resetFields(["productId"]);
  };

  const handleOrderSearch = (value) => {
    if (value) {
      const filtered = orders.filter((order) =>
        order.value.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOrderId(filtered);
    } else {
      setFilteredOrderId([]);
    }
  };

  const validateOrderId = (_, value) => {
    if (!value || orders.some((order) => order.value === value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please select a valid Order ID"));
  };

  const fetchProductRequests = async () => {
    const email = getEmailForFetch();
    try {
      const response = await getProductRequests(userDetails._id, email);
      const sortedProductRequests = response?.data?.data?.productRequests.sort(
        (a, b) => new Date(b.requestTime) - new Date(a.requestTime)
      );
      setProductRequests(sortedProductRequests || []);
    } catch (error) {
      message.error("Failed to load product requests");
      console.error("Fetch error:", error);
    }
  };

  const handleSubmit = async (values) => {
    const email = getEmailForFetch();
    setRequestModal(false);

    const adminEmail = email;
    const userId = userDetails._id;

    try {
      await requestProduct({
        orderId: values.orderId,
        userId,
        products: [
          {
            productId: values.productId,
            subCategoryId: values.subCategoryId,
            categoryId: values.categoryId,
            quantity: values.quantity,
          },
        ],
        productCost: values.productCost,
        email: adminEmail,
      });

      message.success("Product request submitted successfully!");
      form.resetFields();
      fetchProductRequests();
    } catch (error) {
      console.error("Error submitting request:", error);
      message.error(
        "Failed to submit product request. Please check your connection."
      );
    }
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    editingForm.setFieldsValue({
      orderId: record.orderId,
      engineerId: record.engineerId?._id,
      products: record.products.map((p) => ({
        productId: p.productId?._id,
        quantity: p.quantity,
      })),
    });
    setEditModal(true);
  };

  const handleEditSubmit = async (values) => {
    setEditModal(false);
    const email = getEmailForFetch();
    try {
      const updatedProducts = [
        {
          productId: values.productId,
          subCategoryId: values.subCategoryId,
          categoryId: values.categoryId,
          quantity: values.quantity,
        },
      ];

      await editProductRequest(editingRecord._id, {
        userId: userDetails._id,
        email: email,
        products: updatedProducts,
      });

      message.success("Product request updated successfully!");
      editingForm.resetFields();
      fetchProductRequests(); // Refresh the list after edit
    } catch (error) {
      console.error("Error editing product request:", error);
      message.error(
        "Failed to update product request. Please check your connection."
      );
    }
  };

  const handleDelete = async (id) => {
    const email = getEmailForFetch();
    try {
      await deleteRequestProduct(id);
      message.success("Product request deleted successfully");
      fetchProductRequests(email);
    } catch (error) {
      message.error("Failed to delete product request");
    }
  };

  // const handleFormReset = () => {
  //   setCategoryName("");
  //   setSubCategoryName("");
  //   setProductName("");
  //   form.resetFields();
  // };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Requested Person",
      dataIndex: "userId",
      key: "userId",
      render: (engineer) => engineer?.name || "N/A",
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) =>
        products?.map((product) => (
          <div key={product.productId?._id || product._id}>
            {product.productId?.name || "Unknown Product"} - {product.quantity}
          </div>
        )) || "No products available",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "product_delivered" ? "green" : "volcano"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Space>
          <Button onClick={() => handleEdit(record)} type="link">
            <EditOutlined />
          </Button>
          <Button>
            <Popconfirm
              title="Are you sure to delete this Product Request?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(record._id)}
            >
              <DeleteOutlined style={{ color: "#ff4d4f" }} />
            </Popconfirm>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={12} offset={12} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => setRequestModal(true)}>
            Request Product <PlusCircleFilled />
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={productRequests}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      <Modal
        open={requestModal}
        onCancel={() => {
          setRequestModal(false);
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Order ID"
            name="orderId"
            rules={[
              {
                validator: validateOrderId,
              },
            ]}
          >
            <AutoComplete
              options={filteredOrderId}
              onSearch={handleOrderSearch}
              placeholder="Select or search for Order ID"
              filterOption={false}
            />
          </Form.Item>
          <Form.Item
            name="categoryId"
            label="Category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select
              onChange={handleCategoryChange}
              placeholder="Select a category"
            >
              {categories.map((category) => (
                <Select.Option key={category._id} value={category._id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="subCategoryId"
            label="Subcategory"
            rules={[
              { required: true, message: "Please select a subcategory!" },
            ]}
          >
            <Select
              onChange={handleSubCategoryChange}
              placeholder="Select a subcategory"
            >
              {subCategories.map((subCategory) => (
                <Select.Option key={subCategory._id} value={subCategory._id}>
                  {subCategory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="productId"
            label="Product"
            rules={[{ required: true, message: "Please select a product!" }]}
          >
            <Select placeholder="Select a product">
              {products.map((product) => (
                <Select.Option key={product._id} value={product._id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="productCost"
            label="Product Cost"
            rules={[{ required: true, message: "Please Enter product cost!" }]}
          >
            <Input placeholder="Enter Product Cost" />
          </Form.Item> */}
          {/* <Form.Item
            label="Category"
            name="categoryId"
            rules={[{ required: true }]}
          >
            <AutoComplete
              options={filteredCategories.map((category) => ({
                value: category.name,
                id: category._id,
              }))}
              onSearch={handleCategorySearch}
              onSelect={handleCategorySelect}
              placeholder="Select Category"
              filterOption={false}
            />
          </Form.Item>

          <Form.Item
            label="Subcategory"
            name="subCategoryId"
            rules={[{ required: true }]}
          >
            <AutoComplete
              options={filteredSubCategories.map((subCategory) => ({
                value: subCategory.name,
                id: subCategory._id,
              }))}
              onSearch={handleSubCategorySearch}
              onSelect={handleSubCategorySelect}
              placeholder="Select Subcategory"
              filterOption={false}
            />
          </Form.Item>

          <Form.Item
            label="Product"
            name="productId"
            rules={[{ required: true }]}
          >
            <AutoComplete
              options={filteredProducts.map((product) => ({
                value: product.name,
                id: product._id,
              }))}
              onSearch={handleProductSearch}
              onSelect={handleProductSelect}
              placeholder="Select Product"
              filterOption={false}
            />
          </Form.Item> */}

          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: "Please enter quantity!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={editModal}
        onCancel={() => setEditModal(false)}
        footer={null}
      >
        <Form form={editingForm} onFinish={handleEditSubmit}>
          <Form.Item
            name="orderId"
            label="Order ID"
            rules={[
              { required: true, message: "Enter Order Id" },
              { validator: validateOrderId },
            ]}
          >
            <AutoComplete
              options={filteredOrderId}
              onSearch={handleOrderSearch}
              placeholder="Enter Order Id"
              filterOption={false}
            />
          </Form.Item>

          <Form.Item
            name="categoryId"
            label="Category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select
              onChange={handleCategoryChange}
              placeholder="Select a category"
            >
              {categories.map((category) => (
                <Select.Option key={category._id} value={category._id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="subCategoryId"
            label="Subcategory"
            rules={[
              { required: true, message: "Please select a subcategory!" },
            ]}
          >
            <Select
              onChange={handleSubCategoryChange}
              placeholder="Select a subcategory"
            >
              {subCategories.map((subCategory) => (
                <Select.Option key={subCategory._id} value={subCategory._id}>
                  {subCategory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="productId"
            label="Product"
            rules={[{ required: true, message: "Please select a product!" }]}
          >
            <Select placeholder="Select a product">
              {products.map((product) => (
                <Select.Option key={product._id} value={product._id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="productCost"
            label="Product Cost"
            rules={[{ required: true, message: "Please Enter product cost!" }]}
          >
            <Input placeholder="Enter Product Cost" />
          </Form.Item>

          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: "Please enter quantity!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};

export default StoreRequest;
